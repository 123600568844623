import { Route } from '@/types/generalTypes';
import { ROUTES } from '@/lib/constants.mjs';
import JournalingIcon from '#/images/menu/menu_bg_journaling.svg';
import VerificationIcon from '#/images/menu/menu_bg_verification.svg';
import TrackingIcon from '#/images/menu/menu_bg_tracking.svg';

const useRoutes = (): Route[] => {
    return [
        {
            visibleName: 'feature_menu_item_label',
            route: null,
            icon: null,
            subItems: [
                {
                    visibleName: 'journal_menu_item_label',
                    route: ROUTES.JOURNALING,
                    icon: JournalingIcon,
                    subTitle: 'journal_menu_item_subtitle',
                },
                {
                    visibleName: 'verification_pages_menu_item_label',
                    route: ROUTES.VERIFICATION,
                    icon: VerificationIcon,
                    subTitle: 'verification_menu_item_subtitle',
                },
                {
                    visibleName: 'analytics_menu_item_label',
                    route: ROUTES.TRACKING_ANALYTICS,
                    icon: TrackingIcon,
                    subTitle: 'analytics_menu_item_subtitle',
                },
            ],
        },
        {
            visibleName: 'pricing_menu_item_label',
            route: ROUTES.PLANS,
            icon: null,
            subItems: [],
        },
        {
            visibleName: 'company_menu_item_label',
            route: null,
            icon: null,
            subItems: [
                {
                    visibleName: 'contact_us_menu_item_label',
                    route: ROUTES.CONTACT_US,
                    icon: null,
                },
                {
                    visibleName: 'testimonials_menu_item_label',
                    route: ROUTES.TESTIMONIALS,
                    icon: null,
                },
                {
                    visibleName: 'blog_menu_item_label',
                    route: ROUTES.BLOG,
                    disabledPrefetch: true,
                    icon: null,
                },
            ],
        },
        {
            visibleName: 'leaderboard_menu_item_label',
            route: ROUTES.LEADERBOARD,
            icon: null,
            subItems: [],
        },
        {
            visibleName: 'intelligence_menu_item_label',
            route: ROUTES.INTELLIGENCE,
            icon: null,
            subItems: [],
        },
    ];
};

export default useRoutes;
